<template>
    <v-card flat class="rounded-lg">
        <v-card v-if="maquinista_datos" flat class="mt-3">
            <!-- Lista de turnos asignados -->
            <v-list v-if="modo == 'asignar'">
                <v-list-item class="px-1 rounded-lg py-0" v-for="(turno) in maquinista.asignados" :key="turno.id">
                    <v-list-item-avatar class="my-0 mr-1">
                        <v-avatar color="secondary" size="35">
                            <v-icon size="20" color="primary" v-if="!turno.edit">mdi-check</v-icon>
                            <v-icon size="20" color="primary" v-else>mdi-plus</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                        <v-list-item-title class="text-subtitle-2 grey--text text--darken-1 text-capitalize mb-0">
                            <span v-if="turno.nombre"><span class="text-capitalize">{{turno.nombre}} </span> </span>
                            <span v-else >Turno <span class="text-uppercase">{{turno.turno}} </span> </span>
                            <v-chip class="ma-0" color="grey lighten-2" small v-if="turno.extra">
                                <v-icon left size="12" class="mr-1" color="grey darken-2">
                                    mdi-check
                                </v-icon>
                                <span class="grey--text text--darken-2">Extra</span>
                            </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="!turno.edit">
                            Asignado
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon @click="EliminarAsignado(turno.id)">
                            <v-icon color="red" size="20">mdi-delete-outline</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <!--Turno por asignar-->
            <v-list-item class="px-1 rounded-lg py-0" >
                <v-list-item-avatar class="my-0 mr-1">
                    <v-avatar color="secondary" size="35">
                        <v-icon size="20" color="primary" v-if="modo == 'asignar'">mdi-plus</v-icon>
                        <v-icon size="20" color="primary" v-else>mdi-pencil-outline</v-icon>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                    <v-list-item-title class="text-subtitle-2 grey--text text--darken-1 text-capitalize mb-0">
                        <span v-if="maquinista.turno.nombre"><span class="text-capitalize">{{maquinista.turno.nombre}} </span> </span>
                        <span v-else >Turno <span class="text-uppercase">{{maquinista.turno.turno}} </span> </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Nuevo
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="maquinista.turno.extra">
                        <v-chip class="ma-0" color="grey lighten-2" small >
                            <v-icon left size="12" class="mr-1" color="grey darken-2">
                                mdi-check
                            </v-icon>
                            <span class="grey--text text--darken-2">Extra</span>
                        </v-chip>
                        <!--<v-chip class="ma-0 ml-1" color="grey lighten-2" small >
                            <v-icon left size="12" class="mr-1" color="grey darken-2">
                                mdi-check
                            </v-icon>
                            <span class="grey--text text--darken-2">Extra</span>
                        </v-chip>-->
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="modo == 'asignar'">
                    <v-btn icon @click="turno_info_card = !turno_info_card">
                        <v-icon color="primary" size="20">mdi-pencil-outline</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-card>
        <v-expand-transition>
            <v-card outlined v-if="turno_info_card" class="rounded-lg mt-2" :disabled="asignar_state">
                <v-tabs centered mobile-breakpoint="400" v-model="vista" height="42" class="pt-1">
                    <v-tabs-slider color=primary></v-tabs-slider>
                    <v-tab class="rounded-lg">Turno</v-tab>
                    <v-tab class="rounded-lg">Detalle</v-tab>
                </v-tabs>
                <v-tabs-items v-model="vista">
                    <v-tab-item transition="fade" reverse-transition="fade">
                        <v-container >
                            <v-row>
                                <v-col cols="12" class="pa-0 text-center" offset="5">
                                    <v-checkbox v-model="maquinista.turno.extra" label="Extra" hide-details></v-checkbox>
                                </v-col>
                                <v-col cols="4" class="pr-1">
                                    <v-text-field label="Inicio" v-model="maquinista.turno.inicio" type="time" outlined dense hide-details @blur="CalculoDuracion()"/>
                                </v-col>
                                <v-col cols="4" class="px-1">
                                    <v-text-field label="Termino" v-model="maquinista.turno.termino" type="time" outlined dense hide-details @blur="CalculoDuracion()"/>
                                </v-col>
                                <v-col cols="4" class="pl-1">
                                    <v-text-field label="Duracion" v-model="maquinista.turno.duracion" type="time" outlined dense hide-details/>
                                </v-col>
                                <v-col cols="6" class="pr-1">
                                    <v-select :items="lugares" v-model="maquinista.turno.entrada" label="Entrada" dense outlined append-icon="" hide-details item-text="nombre" item-value="valor" />
                                </v-col>
                                <v-col cols="6" class="pl-1">
                                    <v-select :items="lugares" v-model="maquinista.turno.salida" label="Salida" dense outlined append-icon="" hide-details item-text="nombre" item-value="valor" />
                                </v-col>
                                <v-col cols="12" class="text-center pt-0">
                                    <v-btn text color="primary" @click="RestablecerTurnoInfo()">
                                        <v-icon left>
                                            mdi-refresh
                                        </v-icon>
                                        Reestabelcer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item transition="fade" reverse-transition="fade">    
                        <v-list flat class="px-2" v-if="maquinista.turno.detalle.length" :key="key_list">
                            <v-list-item class="rounded-lg px-0" v-for="(servicio) in maquinista.turno.detalle" :key="servicio.id" :disabled="servicio.maquinista?true:false">
                                <v-list-item-icon class="lista-turno mr-3 ml-2 my-2">
                                    <v-icon color="grey" size="20" style="margin-top:2px">{{ServicioIcono(servicio.servicio, servicio.dir)}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium grey--text text--darken-1" v-if="servicio.servicio">{{servicio.servicio}}
                                        <v-list-item-subtitle class="font-weight-regular">{{FormatRecorrido(servicio.dir)}}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="red--text text-capitalize" v-if="servicio.maquinista">
                                            <v-icon color="red" size="15" class="mb-1">mdi-alert-circle-outline</v-icon>
                                            {{servicio.maquinista}}
                                            </v-list-item-subtitle>
                                    </v-list-item-title>
                                    <v-list-item-title class="my-0 text-subtitle-2 text-uppercase font-weight-medium grey--text text--darken-1" v-else>
                                        {{servicio.dir}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span class="grey--text text-subtitle-2">{{servicio.inicio}} - {{servicio.termino}}</span>
                                </v-list-item-action>
                                <v-list-item-action class="ml-1 my-0">
                                    <v-btn icon @click="AnularTurnoDetalle(servicio.id)" v-if="(!servicio.mod ||servicio.mod == 0) && !servicio.maquinista">
                                        <v-icon color="red" size="20">mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="RestaurarTurnoDetalle(servicio.id)" v-if="servicio.mod == 1  && !servicio.maquinista">
                                        <v-icon color="primary" size="20">mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="EliminarTurnoDetalle(servicio.id)" v-if="servicio.mod == 2  && !servicio.maquinista">
                                        <v-icon color="red" size="20">mdi-delete-outline</v-icon>
                                    </v-btn>
                                     <v-btn icon v-if="servicio.maquinista">
                                        <v-icon color="grey" size="20">mdi-close</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete :items="ServiciosLista" outlined dense chips small-chips label="Busca un servicio" multiple item-value="id" item-text="turno" :menu-props="{offsetY: true, rounded:'lg', transition:'slide-y-transition'}" >
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item link @click="AñadirServicio(data.item)" :disabled="data.item.nombre && data.item.mod_tipo == 2?true:false">
                                                    <v-list-item-avatar class="my-1 mr-1">
                                                        <v-avatar size="30" color="secondary">
                                                            <v-icon size="15" color="primary">{{ServicioIcono(data.item.servicio, data.item.dir)}}</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium grey--text text--darken-1" v-if="data.item.servicio">
                                                            {{data.item.servicio}}
                                                        </v-list-item-title>
                                                        <v-list-item-title class="my-0 text-subtitle-2 text-uppercase font-weight-medium grey--text text--darken-1" v-else>
                                                            {{data.item.dir}}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>{{data.item.inicio}} - {{data.item.termino}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="red--text text-capitalize" v-if="data.item.nombre && data.item.mod_tipo == 2">
                                                            <v-icon color="red" size="15" class="mb-1">mdi-alert-circle-outline</v-icon>
                                                            {{data.item.nombre}}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-avatar size="32" color="secondary" tile class="rounded-lg" width="35">
                                                            <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium grey--text text--darken-1 text-uppercase primary--text">
                                                                {{data.item.turno}}
                                                            </v-list-item-title>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expand-transition>
        <v-card flat class="mx-10 text-center mt-2">
            <v-btn color="primary" depressed v-if="maquinista.turno && maquinista_datos && modo == 'asignar'" width="60%" @click="AsignarMaquista()"  :loading="asignar_state">
                Asignar
            </v-btn>
            <v-btn color="primary" depressed v-if="maquinista.turno && maquinista_datos && modo == 'editar'" width="60%" @click="EditarMaquista()"  :loading="asignar_state">
                Editar
            </v-btn>
        </v-card>
    </v-card>
</template>

<script>
import dayjs from "dayjs"
import { mapMutations, mapState } from 'vuex'
export default {
    name: 'AsignarDetalle',
    props: ['turno_datos','maquinista_datos', 'modo','fecha'],
    data() {
        return {
            key_list : Math.floor(Math.random() * 100),
            asignar_state: false,
            turno_info_card: false,
            vista: 0,
            lugares: [
                { nombre: 'Puerto', valor: 'pu'}, 
                { nombre: 'Limache', valor: 'li' }, 
                { nombre: 'S.Aldea', valor: 'sa' }, 
                { nombre: 'El Belloto', valor: 'eb' }
            ],
            servicios_lista: [],
            turno:{
                datos:{
                    entrada: null,
                    salida: null,
                    inicio: null, 
                    termino: null, 
                    duracion: null,
                    detalle:[]
                }
            }, 
            maquinista: {
                asignados: [],
                turno:{
                   entrada: null,
                    salida: null,
                    inicio: null, 
                    termino: null, 
                    duracion: null,
                    detalle:[]
                }
            }
        }
    },
    computed:{
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        //Entrega la lista de servicios del dia (todos menos los propios del turno)
        ServiciosLista(){
            let maquinista_detalle_lista = this.maquinista.turno.detalle.map(id_detalle => { return id_detalle.id }),
                servicio_filtro =  this.servicios_lista.filter(servicio => !maquinista_detalle_lista.includes(servicio.id))
            return servicio_filtro
        }
    },
    methods:{
        ...mapMutations([ 'ErrorRequest','CloseDialog']),
        //Ordena los datos recibidos por el componente Asignar
        GetData(){  
            if(this.turno_datos){ //Asigna y define los datos del turno (sin modificar)
                this.ListaServicios()
                if(this.turno_datos.entrada) this.turno.datos.entrada = this.turno_datos.entrada
                if(this.turno_datos.salida) this.turno.datos.salida = this.turno_datos.salida
                if(this.turno_datos.inicio) this.turno.datos.inicio = this.turno_datos.inicio
                if(this.turno_datos.termino) this.turno.datos.termino = this.turno_datos.termino
                if(this.turno_datos.duracion) this.turno.datos.duracion = this.turno_datos.duracion
                this.turno.datos.detalle = this.turno_datos.detalle
            }
            if(this.maquinista_datos && this.modo == 'asignar'){
                this.maquinista.asignados = this.maquinista_datos.turno
                //Comprobacion si existen el turno libre dentro de los asignados
                let libre = this.maquinista.asignados.map(function(e) { return e.turno }).indexOf('l')
                if(libre > -1) this.maquinista.asignados.splice(libre, 1)
                //Comprobacion si existen el turno desconocido dentro de los asignados
                let desconocido = this.maquinista.asignados.map(function(e) { return e.turno }).indexOf('?')
                if(desconocido > -1) this.maquinista.asignados.splice(desconocido, 1)
                //Asigna las propiedades del turno al nuevo turno por asignar.
                if(this.turno_datos.entrada) this.maquinista.turno.entrada = this.turno_datos.entrada
                if(this.turno_datos.salida) this.maquinista.turno.salida = this.turno_datos.salida
                if(this.turno_datos.inicio) this.maquinista.turno.inicio = this.turno_datos.inicio
                if(this.turno_datos.termino) this.maquinista.turno.termino = this.turno_datos.termino
                if(this.turno_datos.duracion) this.maquinista.turno.duracion = this.turno_datos.duracion
                this.maquinista.turno.extra = 1
                this.maquinista.turno.detalle = this.turno_datos.detalle
                this.maquinista.turno.turno = this.turno_datos.turno
                this.maquinista.turno.nombre = this.turno_datos.nombre
                
                //Comprueba la cantidad de turnos asignados
                if(this.maquinista.asignados.length == 0) this.maquinista.turno.extra = 0
            }
            if(this.maquinista_datos && this.modo == 'editar'){
                this.maquinista.turno = JSON.parse(JSON.stringify(this.maquinista_datos))
                this.maquinista.turno.detalle = JSON.parse(JSON.stringify(this.turno_datos.detalle))
                this.maquinista.turno.detalle.forEach( ( servicio )=> { servicio.mod = 0 })
                if(this.maquinista.turno.detalle && this.maquinista_datos.detalle){
                    for (let index = 0; index < this.maquinista.turno.detalle.length; index++) {
                        let servicio_mod = this.maquinista_datos.detalle.find( servicio => servicio.id === this.maquinista.turno.detalle[index].id)
                        if(servicio_mod){
                            if(servicio_mod.maquinista){
                                this.maquinista.turno.detalle[index].maquinista = servicio_mod.maquinista
                            }
                            if(servicio_mod.condicion){
                                this.maquinista.turno.detalle[index].mod = servicio_mod.condicion
                            }
                        }
                    }
                }
                for (let index = 0; index < this.maquinista_datos.detalle.length; index++) {
                    if(this.maquinista_datos.detalle[index].condicion == 2){
                        let servicio_agregado = this.maquinista_datos.detalle[index]
                        servicio_agregado.mod = 2
                        this.maquinista.turno.detalle.push(servicio_agregado)
                    }
                }
                this.turno_info_card = true
            }
        },
        //Elimina el turno elegido dentro de los turnos asignados al maquinista.
        EliminarAsignado(id){
            var index = this.maquinista.asignados.map(function(e) { return e.id }).indexOf(id)
            if (index > -1) this.maquinista.asignados.splice(index, 1)
            if(this.maquinista.asignados.length == 0) this.maquinista.turno.extra = 0
        },
        //Entrega el string para el titulo del nombre
        TurnoNombre(turno, nombre){
            let titulo =  `Turno ${turno}`
            if(nombre) titulo = nombre
            return titulo
        },
        //Define el icono del servicio o detalle.
        ServicioIcono(servicio, direccion) {
            let icono = null
            if (servicio > 0) {
                switch (direccion) {
                    case 'pu-li':
                        icono = 'mdi-arrow-up'
                        break
                    case 'li-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'pu-sa':
                        icono = 'mdi-arrow-up'
                        break
                    case 'sa-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'pu-eb':
                        icono = 'mdi-arrow-up'
                        break
                    case 'eb-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'sa-mi':
                        icono = 'mdi-arrow-down'
                        break
                    case 'po-sa':
                        icono = '   mdi-arrow-up'
                        break
                }
            } else {
                direccion == 'descanso' ? icono = 'mdi-coffee-outline' : icono = 'mdi-checkbox-blank-circle-outline'
            }
            return icono
        },
        //Entrega el recorrido del servicio con base en las estaciones de inicio y termino.
        FormatRecorrido(recorrido) {
            let nombre_recorrido
            switch (recorrido) {
                case 'pu-li':
                    nombre_recorrido = 'Puerto - Limache'
                    break
                case 'li-pu':
                    nombre_recorrido = 'Limache - Puerto'
                    break
                case 'pu-sa':
                    nombre_recorrido = 'Puerto - S.Aldea'
                    break
                case 'sa-pu':
                    nombre_recorrido = 'S.Aldea - Puerto'
                    break
                case 'eb-pu':
                    nombre_recorrido = 'El Belloto - Puerto'
                    break
                case 'pu-eb':
                    nombre_recorrido = 'Puerto - El Belloto'
                    break
                case 'sa-mi':
                    nombre_recorrido = 'S.Aldea - Miramar'
                    break
                case 'po-sa':
                    nombre_recorrido = 'Portales - S.Aldea'
                    break
                default:
                    break
            }
            return nombre_recorrido
        },
        //Calcula la duracion del turno con base en la hora de inicio y termino.
        CalculoDuracion(){
            if (this.maquinista.turno.inicio && this.maquinista.turno.termino) {
                if (this.maquinista.turno.inicio.length == 5 && this.maquinista.turno.termino.length == 5) {
                    let inicio = dayjs().hour(this.maquinista.turno.inicio.slice(0, -3)).minute(this.maquinista.turno.inicio.slice(3)),
                        termino = dayjs().hour(this.maquinista.turno.termino.slice(0, -3)).minute(this.maquinista.turno.termino.slice(3)),
                        duracion_horas = termino.diff(inicio, 'hour'),
                        duracion_minutos = (termino.diff(inicio, 'minutes') % 60)
                    if(duracion_horas < 10) duracion_horas = `0${duracion_horas}`
                    if(duracion_minutos < 10) duracion_minutos = `0${duracion_minutos}`
                    let duracion = `${duracion_horas}:${duracion_minutos}`
                    if(duracion.includes('-')) this.maquinista.turno.duracion = '00:00'
                    else this.maquinista.turno.duracion = duracion
                } else {
                   this.maquinista.turno.duracion = '00:00'
                }
            } else {
                this.maquinista.turno.duracion = '00:00'
            }
        },
        async AsignarMaquista() {
            this.asignar_state = true
            let asignar_datos = [
                {
                    fecha: this.fecha,
                    usuario_id: this.maquinista_datos.usuario_id,
                    turno:[]
                }
            ]
            //Carga los datos de los turnos ya asignados al objeto asignar_datos.
            this.maquinista.asignados.forEach(function(turno_datos){
                let turno = {
                    extra: turno_datos.extra,
                    id: turno_datos.id
                }
                asignar_datos[0].turno.push(turno)
            })
            let turno_nuevo = {
                id: this.turno_datos.id,
                extra: this.maquinista.turno.extra,
                mod:{
                    inicio: null,
                    termino: null,
                    entrada: null,
                    salida: null,
                    duracion: null,
                    detalle: []
                }
            }
            //Comprueba si algun dato fue modificado respecto al turno original.
            if(this.turno_datos.inicio != this.maquinista.turno.inicio) turno_nuevo.mod.inicio = this.maquinista.turno.inicio
            if(this.turno_datos.termino != this.maquinista.turno.termino) turno_nuevo.mod.termino = this.maquinista.turno.termino
            if(this.turno_datos.entrada != this.maquinista.turno.entrada) turno_nuevo.mod.entrada = this.maquinista.turno.entrada
            if(this.turno_datos.salida != this.maquinista.turno.salida) turno_nuevo.mod.salida = this.maquinista.turno.salida
            if(this.turno_datos.duracion != this.maquinista.turno.duracion) turno_nuevo.mod.duracion = this.maquinista.turno.duracion
            //Recorre la lista de detalles en busca de servicios anulados o añadidos
            this.maquinista.turno.detalle.forEach(function(detalle){
                if(detalle.mod){
                    let detalle_mod = {
                        id: detalle.id,
                        tipo: detalle.mod
                    }
                    turno_nuevo.mod.detalle.push(detalle_mod)
                }
            })
            asignar_datos[0].turno.push(turno_nuevo)
            let datos = {
                asignar: asignar_datos,
                code: 1
            }
            //console.log(datos)
            await this.axios.post(`/planilla/edit/`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.turno = true
                        this.update.planilla = true
                        this.CloseDialog('asignar')
                        this.asignar_state = false
                    }
                }
                //this.listamaq = respuesta.data.lista
            }).catch(err => {
                this.asignar_state = false
                this.ErrorRequest(err)
            })
        },
        //Envia los datos de la edicion del turno.
        async EditarMaquista() {
            this.asignar_state = true
            let datos = {
                editar: {
                    fecha: this.fecha,
                    usuario_id: this.maquinista_datos.id,
                    turno:{
                        id: this.turno_datos.id,
                        extra: this.maquinista.turno.extra,
                        mod:{
                            inicio: null,
                            termino: null,
                            entrada: null,
                            salida: null,
                            duracion: null,
                            detalle: []
                        }
                    }
                },
                code: 3
            }
            //Comprueba si algun dato fue modificado respecto al turno original.
            if(this.turno_datos.inicio != this.maquinista.turno.inicio) datos.editar.turno.mod.inicio = this.maquinista.turno.inicio
            if(this.turno_datos.termino != this.maquinista.turno.termino) datos.editar.turno.mod.termino = this.maquinista.turno.termino
            if(this.turno_datos.entrada != this.maquinista.turno.entrada) datos.editar.turno.mod.entrada = this.maquinista.turno.entrada
            if(this.turno_datos.salida != this.maquinista.turno.salida) datos.editar.turno.mod.salida = this.maquinista.turno.salida
            if(this.turno_datos.duracion != this.maquinista.turno.duracion) datos.editar.turno.mod.duracion = this.maquinista.turno.duracion
            //Recorre la lista de detalles en busca de servicios anulados o añadidos.
            this.maquinista.turno.detalle.forEach(function(detalle){
                if(detalle.mod){
                    let detalle_mod = {
                        id: detalle.id,
                        tipo: detalle.mod
                    }
                    datos.editar.turno.mod.detalle.push(detalle_mod)
                }
            })
            //console.log(datos)
            await this.axios.post(`/planilla/edit/`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.turno = true
                        this.update.planilla = true
                        this.CloseDialog('editar')
                        this.asignar_state = false
                    }
                }
                //this.listamaq = respuesta.data.lista
            }).catch(err => {
                this.asignar_state = false
                this.ErrorRequest(err)
            })
        },
        //Reestablece la informacion del turno con base en el original 
        RestablecerTurnoInfo(){
            this.maquinista.turno.entrada =  this.turno_datos.entrada
            this.maquinista.turno.salida =  this.turno_datos.salida
            this.maquinista.turno.inicio =  this.turno_datos.inicio
            this.maquinista.turno.termino =  this.turno_datos.termino
            this.maquinista.turno.duracion =  this.turno_datos.duracion
        },
        //Anula el servicio del turno (aplicable a servicios propios del turno)
        AnularTurnoDetalle(id){
            if(id){
                
                var index = this.maquinista.turno.detalle.map(function(e) { return e.id }).indexOf(id)
                //this.$set(this.maquinista.turno.detalle[index], 'mod', 1)
                this.maquinista.turno.detalle[index].mod = 1
                this.key_list = Math.floor(Math.random() * 100)
            }
        },
        //Restaura el servicio solicitado previamente anulado.
        RestaurarTurnoDetalle(id){
            if(id){
                var index = this.maquinista.turno.detalle.map(function(e) { return e.id }).indexOf(id)
                this.maquinista.turno.detalle[index].mod = 0
                this.key_list = Math.floor(Math.random() * 100)
            }
        },
        //Consulta media la API todos los servicios del dia.
        async ListaServicios(){
            let fecha = dayjs(this.fecha).format('YYYYMMDD')
            await this.axios.get(`/servicio/${fecha}/lista`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.servicios_lista = respuesta.data
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Elimina el servicio solicitado (aplicable solo a servicios no propios del turno)
        EliminarTurnoDetalle(id){
            if(id){
                var index = this.maquinista.turno.detalle.map(function(e) { return e.id }).indexOf(id)
                if (index > -1) this.maquinista.turno.detalle.splice(index, 1)
                //this.key_list = Math.floor(Math.random() * 100)
            }
        },
        AñadirServicio(servicio){
            servicio.mod = 2
            //this.maquinista.turno.detalle = [... this.maquinista.turno.detalle, servicio]
            this.maquinista.turno.detalle.push(servicio)
            //this.key_list = Math.floor(Math.random() * 100)
        }
    },
    mounted(){
        this.GetData()
    },
    watch:{
        turno_datos: function () {
            this.GetData()
        },
        maquinista_datos: function(){
            this.GetData()
        }
    }
    /*
    asignar: [
        {
        fecha: '2021-05-03',
        usuario_id: 'f27c5400-ae83-11eb-8db4-00155df1842f',
        turno: [
                {
                    extra: 0,
                    id: "e5b17336-ae83-11eb-8db4-00155df1842f",
                    mod:{
                        duracion: "06:15"
                        entrada: null
                        inicio: "07:00"
                        salida: null
                        termino: null
                    }
                }
            ]
        }
    ],
    code: 1*/
}
</script>

<style scoped>
.servicio-disabled{
    opacity: .5;
}
</style>